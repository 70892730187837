import React from 'react';
import './loader.scss';

const LoaderLogo = () => {
  return (
    <div className="ergo-loader">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0808 12.5252C11.6364 8.83381 11.5561 6.10535 13.0006 4.33988C14.6083 2.3876 17.8595 1.79114 20.5787 1.69797C21.1108 1.98457 21.6181 2.30256 22.0973 2.64865L22.0973 2.64866C22.7027 3.10271 23.3081 3.55676 23.8378 4.16216C26.4108 6.65946 28 10.1405 28 14C28 17.8595 26.4108 21.3405 23.9135 23.9135C21.4162 26.4865 17.8595 28 14 28C13.1483 28 12.2966 27.9112 11.4818 27.7522C12.1534 27.506 12.7573 27.1569 13.3216 26.649C15.9698 24.2416 15.8895 19.5069 13.0808 12.5252ZM5.73138 25.2195C5.42713 24.9973 5.13107 24.7636 4.84324 24.5189C1.89189 22.0216 0 18.2378 0 14C0 10.1405 1.58919 6.65946 4.08649 4.08649C6.65946 1.58919 10.1405 0 14 0C14.4973 0 14.9819 0.0255363 15.4537 0.0766081C13.7866 0.603686 12.2014 1.45728 10.9944 2.81516C8.98815 5.30286 8.9079 8.83381 10.7536 13.408C13.0808 19.1859 13.4018 23.1983 11.6364 24.8835C10.4015 26.1184 7.91736 25.9725 5.73138 25.2195Z" fill="#FFD12C" />
      </svg>
    </div>
  );
};

export default LoaderLogo;
